import { MenuItem } from '@app/one-models/models/menu-item';

import { UserProfilePermissions } from './user-profile-permissions';
import { UserProfileSettings } from './user-profile-settings';


export enum PermissionScope {
  articles = 'articles',
  assets = 'assets',
  settings = 'settings',
  dataplatform = 'dataplatform',
  superadmin = 'superadmin',
  imports = 'imports',
  translationManager = 'translation-manager',
  exports = 'exports',
  controlPanel = 'control-panel',
  common = 'common',
}

export enum PermissionName {
  install = 'install',
  manage = 'manage',
  open = 'open',
  share = 'share',
  reporting = 'reporting',
  idp = 'idp',
  tecdoc = 'tecdoc',
  autocare = 'autocare',
  autocareMaintenance = 'autocareMaintenance',
  cancel = 'cancel',
  delete = 'delete',
  readOnly = 'read-only',
}


export class UserProfile {
  id: string;
  userId: number;
  dataSupplierId: number;
  email: string;
  notificationEmail: string;
  fullName: string;
  role: string;
  roleId: number;
  navigationMenu: MenuItem[];
  permissions: UserProfilePermissions;
  articleGroupIds: number[] = [];
  brandIds: number[] = [];
  settings: UserProfileSettings;

  constructor(model: UserProfile) {
    this.id = model.id;
    this.userId = model.userId;
    this.dataSupplierId = model.dataSupplierId;
    this.email = model.email;
    this.notificationEmail = model.notificationEmail;
    this.fullName = model.fullName;
    this.role = model.role;
    this.roleId = model.roleId;
    this.navigationMenu = model.navigationMenu;
    this.permissions = model.permissions;
    this.articleGroupIds = model.articleGroupIds;
    this.brandIds = model.brandIds;
    this.settings = model.settings;
  }

  hasPermissions(permissions: string[]): boolean {
    const hasPermissions = permissions.filter((m) => {
      const permission = m.split('.');
      return this.hasPermission(permission[0], permission[1]);
    });
    return hasPermissions.length > 0;
  }

  hasPermission(scope: string, name: string): boolean {
    if (this.permissions && this.permissions[scope]) {
      const scopePermissions = this.permissions[scope] as string[];
      if (scopePermissions) {
        return scopePermissions.indexOf(name) > -1;
      }
    }

    return false;
  }
}
